/**
 * Imports
 */
import { List, Statistic, Row, Col, Divider, Typography } from 'antd';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { VibrantlyState } from 'store/types';
import { styled } from 'theme';
import { Collapse } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;
const { Panel } = Collapse;
/**
 * Styling
 */
const Content = styled.div`
  padding: 24px;
`;

const TotalContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * Types
 */
export interface MonthlyReportsPageProps {
  currency: string;
  contract: string;
  contracts: string[];
}

type MerchantSettlementBalance = {
  fxd_txn_fee_amount: number;
  last_update: string;
  pct_txn_fee_amount: number;
  ready_for_payout: boolean;
  settlement_balance_amount: number;
  settlement_id: string;
  setup_fee_amount: number;
  terminal_fee_amount: number;
  transfer_fee_amount: number;
  txn_amount: number;
  txn_count: number;
};
/**
 * HomePage component
 */

const MonthlyGamblingReportsPage: React.FC<MonthlyReportsPageProps> = ({
  currency: settlement_currency,
  contract,
  contracts,
}) => {
  useFirestoreConnect([`monthlyReports_${contract}_${settlement_currency}`]);

  // Declare a new state variable, which we'll call "count"
  const [showSplit, setShowSplit] = React.useState(true);

  const getFormat = (currency: string): string => {
    if (currency === 'GBP') {
      return 'gb-UK';
    } else if (currency === 'DKK') {
      return 'da-DK';
    } else {
      return 'en-GB';
    }
  };
  const monthReportsResult = useSelector(
    (state: VibrantlyState) =>
      state.firestore.data[`monthlyReports_${contract}_${settlement_currency}`],
  );

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency,
    }).format(amount / 100);
  };

  if (!monthReportsResult) {
    return (
      <BaseLayout>
        <Content>Monthly reports</Content>
      </BaseLayout>
    );
  }

  // const currencies = [settlement_currency];

  const collectedFeeRowSize = showSplit ? 4 : 5;
  const clearhausColSize = showSplit ? 4 : 6;
  const vibrantColSize = 5;
  const link4payColSize = 5;

  // const contracts = [
  //   'gauselmann',
  //   'gauselmann_referal',
  // 'novomatic',
  // 'novomatic_referal',
  // ];

  const months = Object.keys(monthReportsResult).sort((v1, v2) =>
    v2.localeCompare(v1),
  );
  return (
    <BaseLayout>
      <Content>
        {`Monthly Report ${contract} ${settlement_currency}`}{' '}
        <input
          type="checkbox"
          checked={showSplit}
          onChange={(e) => setShowSplit(e.target.checked)}
        />
      </Content>
      <Collapse
        defaultActiveKey={[months[0]]}
        onChange={(k) => {
          console.log(k);
        }}
      >
        {months
          .filter((month) => monthReportsResult[month].no_merchants > 0)
          .map((month) => (
            <Panel header={month} key={month}>
              <Row gutter={20}>
                <Col span={2}>
                  <Statistic
                    title="Merchants"
                    value={monthReportsResult[month].no_merchants}
                  />
                </Col>
                <Col span={2}>
                  <Statistic
                    title="Locations"
                    value={monthReportsResult[month].no_locations}
                  />
                </Col>

                <Col span={2}>
                  <Statistic
                    title="Terminals"
                    value={monthReportsResult[month].no_terminals}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={collectedFeeRowSize}>
                  <List
                    size="small"
                    header={<div>Collected Fees</div>}
                    dataSource={
                      monthReportsResult[month].summary.revenue as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                </Col>
                <Col span={clearhausColSize}>
                  <List
                    size="small"
                    header={<div>Clearhaus</div>}
                    dataSource={
                      monthReportsResult[month].summary.split.clearhaus
                        .breakdown as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                </Col>
                {!showSplit &&
                  monthReportsResult[month].summary.commission.breakdown && (
                    <Col span={clearhausColSize}>
                      <List
                        size="small"
                        header={<div>Commission</div>}
                        dataSource={
                          monthReportsResult[month].summary.commission
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ]
                        }
                        renderItem={({ currency, amount, description }) => (
                          <Row justify="space-between">
                            <Col>{`${description}`}</Col>
                            <Col>{formatCurrency(amount, currency)}</Col>
                          </Row>
                        )}
                      />
                    </Col>
                  )}

                {showSplit && (
                  <Col span={link4payColSize}>
                    {monthReportsResult[month].summary.split.link4pay
                      .breakdown && (
                      <List
                        size="small"
                        header={<div>Link4pay</div>}
                        dataSource={
                          monthReportsResult[month].summary.split.link4pay
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ]
                        }
                        renderItem={({ currency, amount, description }) => (
                          <Row justify="space-between">
                            <Col>{`${description}`}</Col>
                            <Col>{formatCurrency(amount, currency)}</Col>
                          </Row>
                        )}
                      />
                    )}
                  </Col>
                )}
                {showSplit && (
                  <Col span={vibrantColSize}>
                    {monthReportsResult[month].summary.split.vibrant
                      .breakdown && (
                      <List
                        size="small"
                        header={<div>Vibrant</div>}
                        dataSource={
                          monthReportsResult[month].summary.split.vibrant
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ]
                        }
                        renderItem={({ currency, amount, description }) => (
                          <Row justify="space-between">
                            <Col>{`${description}`}</Col>
                            <Col>{formatCurrency(amount, currency)}</Col>
                          </Row>
                        )}
                      />
                    )}
                  </Col>
                )}
                {showSplit && (
                  <Col span={4}>
                    {monthReportsResult[month].summary.split
                      .eur_fees_collected && (
                      <List
                        size="small"
                        header={<div>Partner commission</div>}
                        dataSource={
                          monthReportsResult[month].summary.split
                            .eur_fees_collected as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ]
                        }
                        renderItem={({ currency, amount, description }) => (
                          <Row justify="space-between">
                            <Col>{`${description}`}</Col>
                            <Col>{formatCurrency(amount, currency)}</Col>
                          </Row>
                        )}
                      />
                    )}
                  </Col>
                )}
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={1} />
                <Col span={collectedFeeRowSize - 1}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.revenue as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      settlement_currency,
                    )}
                  </TotalContent>
                </Col>
                <Col span={clearhausColSize}>
                  <TotalContent>
                    {monthReportsResult[month].summary.split.clearhaus &&
                      formatCurrency(
                        (monthReportsResult[month].summary.split.clearhaus
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ])
                          .filter(
                            ({ currency }) => currency === settlement_currency,
                          )
                          .reduce((acc, { amount }) => acc + amount, 0),
                        settlement_currency,
                      )}
                  </TotalContent>
                  {settlement_currency != 'EUR' && (
                    <TotalContent>
                      {monthReportsResult[month].summary.split.clearhaus &&
                        formatCurrency(
                          (monthReportsResult[month].summary.split.clearhaus
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(({ currency }) => currency === 'EUR')
                            .reduce((acc, { amount }) => acc + amount, 0),
                          'EUR',
                        )}
                    </TotalContent>
                  )}
                </Col>
                {!showSplit &&
                  monthReportsResult[month].summary.commission.total && (
                    <Col span={clearhausColSize}>
                      <TotalContent>
                        {formatCurrency(
                          monthReportsResult[month].summary.commission.total
                            .amount,
                          settlement_currency,
                        )}
                      </TotalContent>
                    </Col>
                  )}

                {showSplit && (
                  <Col span={link4payColSize}>
                    <TotalContent>
                      {monthReportsResult[month].summary.split.link4pay
                        .breakdown &&
                        formatCurrency(
                          (monthReportsResult[month].summary.split.link4pay
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(
                              ({ currency: c_currency }) =>
                                settlement_currency === c_currency,
                            )
                            .reduce((acc, { amount }) => acc + amount, 0),
                          settlement_currency,
                        )}
                    </TotalContent>
                    {settlement_currency != 'EUR' && (
                      <TotalContent>
                        {formatCurrency(
                          (monthReportsResult[month].summary.split.link4pay
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(({ currency }) => currency === 'EUR')
                            .reduce((acc, { amount }) => acc + amount, 0),
                          'EUR',
                        )}
                      </TotalContent>
                    )}
                  </Col>
                )}
                {showSplit && (
                  <Col span={vibrantColSize}>
                    <TotalContent>
                      {monthReportsResult[month].summary.split.vibrant &&
                        formatCurrency(
                          (monthReportsResult[month].summary.split.vibrant
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(
                              ({ currency: c_currency }) =>
                                settlement_currency === c_currency,
                            )
                            .reduce((acc, { amount }) => acc + amount, 0),
                          settlement_currency,
                        )}
                    </TotalContent>
                    {settlement_currency != 'EUR' && (
                      <TotalContent>
                        {formatCurrency(
                          (monthReportsResult[month].summary.split.vibrant
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(({ currency }) => currency === 'EUR')
                            .reduce((acc, { amount }) => acc + amount, 0),
                          'EUR',
                        )}
                      </TotalContent>
                    )}
                  </Col>
                )}
              </Row>
              <Divider />
              <Row gutter={20}>
                <Col span={1} />
                <Col span={collectedFeeRowSize - 1}>
                  <TotalContent />
                </Col>
                <Col span={clearhausColSize}>
                  <TotalContent>
                    {monthReportsResult[month].summary.split.clearhaus &&
                      formatCurrency(
                        monthReportsResult[month].summary.split.clearhaus.total,
                        settlement_currency,
                      )}
                  </TotalContent>
                </Col>
                {!showSplit &&
                  monthReportsResult[month].summary.commission.total && (
                    <Col span={clearhausColSize}>
                      <TotalContent>
                        {formatCurrency(
                          monthReportsResult[month].summary.commission.total
                            .amount,
                          settlement_currency,
                        )}
                      </TotalContent>
                    </Col>
                  )}

                {showSplit && (
                  <Col span={link4payColSize}>
                    <TotalContent>
                      {monthReportsResult[month].summary.split.link4pay &&
                        formatCurrency(
                          monthReportsResult[month].summary.split.link4pay
                            .total,
                          settlement_currency,
                        )}
                    </TotalContent>
                  </Col>
                )}
                {showSplit && (
                  <Col span={vibrantColSize}>
                    <TotalContent>
                      {monthReportsResult[month].summary.split.vibrant &&
                        formatCurrency(
                          monthReportsResult[month].summary.split.vibrant.total,
                          settlement_currency,
                        )}
                    </TotalContent>
                  </Col>
                )}
              </Row>
              <Divider />
              <Content>
                <Row gutter={16}>
                  <Col span={3}>
                    <Statistic
                      title="Txn Count"
                      value={
                        monthReportsResult[month].summary.transactions[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.transactions[
                              settlement_currency
                            ].no_transactions
                          : 0
                      }
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="Txn Amount"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.transactions[
                              settlement_currency
                            ].txns_amount
                          : 0,
                        settlement_currency,
                      )}
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="Credit Count"
                      value={
                        monthReportsResult[month].summary.transactions[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.transactions[
                              settlement_currency
                            ].credit_txns_count
                          : 0
                      }
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="Credit Amount"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.transactions[
                              settlement_currency
                            ].credit_txns_amount
                          : 0,
                        settlement_currency,
                      )}
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="MDR Amount"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.transactions[
                              settlement_currency
                            ].txns_mdr_amount
                          : 0,
                        settlement_currency,
                      )}
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="Auth Fees"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.transactions[
                              settlement_currency
                            ].txns_auth_fee_settlement_amount
                          : 0,
                        settlement_currency,
                      )}
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="Settlements Amount"
                      value={
                        monthReportsResult[month].summary.settlements[
                          settlement_currency
                        ]
                          ? formatCurrency(
                              monthReportsResult[month].summary.settlements[
                                settlement_currency
                              ].settlement_amount,
                              settlement_currency,
                            )
                          : 0
                      }
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="No. settlements"
                      value={
                        monthReportsResult[month].summary.settlements[
                          settlement_currency
                        ]
                          ? monthReportsResult[month].summary.settlements[
                              settlement_currency
                            ].no_settlements
                          : 0
                      }
                    />
                  </Col>
                </Row>
              </Content>
              {contracts
                .filter(
                  (c) =>
                    monthReportsResult[month].summary.transactions_breakdown[c],
                )
                .map((c) => (
                  <Content key={c}>
                    <h2>{c}</h2>
                    <Row key={c} gutter={16}>
                      {['domestic', 'inter'].map((region) =>
                        ['consumer', 'commercial'].map((product) => (
                          <Col key={`${region}-${product}`} span={4}>
                            <Statistic
                              title={`${region} - ${product}`}
                              value={
                                monthReportsResult[month].summary
                                  .transactions_breakdown[c][
                                  settlement_currency
                                ][region][product]['no_transactions']
                              }
                            />
                          </Col>
                        )),
                      )}
                    </Row>
                  </Content>
                ))}
            </Panel>
          ))}
      </Collapse>
    </BaseLayout>
  );
};

const MonthlyReportsPage: React.FC<{}> = (_props) => {
  useFirestoreConnect(['monthlyReports']);

  const getFormat = (currency: string): string => {
    if (currency === 'GBP') {
      return 'gb-UK';
    } else if (currency === 'DKK') {
      return 'da-DK';
    } else {
      return 'en-GB';
    }
  };
  const monthReportsResult = useSelector(
    (state: VibrantlyState) => state.firestore.data.monthlyReports,
  );

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency,
    }).format(amount / 100);
  };

  if (!monthReportsResult) {
    return (
      <BaseLayout>
        <Content>Monthly reports</Content>
      </BaseLayout>
    );
  }

  const currencies = ['GBP'];

  const contracts = [
    'gauselmann',
    'gauselmann_referal',
    'novomatic',
    'novomatic_referal',
    'ITL',
  ];

  const months = Object.keys(monthReportsResult).sort((v1, v2) =>
    v2.localeCompare(v1),
  );
  return (
    <BaseLayout>
      <Content>Monthly Reports</Content>
      <Collapse
        defaultActiveKey={[months[0]]}
        onChange={(k) => {
          console.log(k);
        }}
      >
        {months.map((month) => (
          <Panel header={month} key={month}>
            <Row gutter={20}>
              <Col span={2}>
                <Statistic
                  title="No. merchant"
                  value={monthReportsResult[month].no_merchants}
                />
              </Col>
              <Col span={2}>
                <Statistic
                  title="No. terminals"
                  value={monthReportsResult[month].no_terminals}
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={4}>
                <List
                  size="small"
                  header={<div>Collected Fees</div>}
                  dataSource={
                    monthReportsResult[month].summary.revenue as [
                      { amount: number; currency: string; description: string },
                    ]
                  }
                  renderItem={({ currency, amount, description }) => (
                    <Row justify="space-between">
                      <Col>{`${description}`}</Col>
                      <Col>{formatCurrency(amount, currency)}</Col>
                    </Row>
                  )}
                />
              </Col>
              <Col span={4}>
                <List
                  size="small"
                  header={<div>Clearhaus</div>}
                  dataSource={
                    monthReportsResult[month].summary.split.clearhaus as [
                      {
                        amount: number;
                        currency: string;
                        description: string;
                      },
                    ]
                  }
                  renderItem={({ currency, amount, description }) => (
                    <Row justify="space-between">
                      <Col>{`${description}`}</Col>
                      <Col>{formatCurrency(amount, currency)}</Col>
                    </Row>
                  )}
                />
              </Col>
              <Col span={5}>
                {monthReportsResult[month].summary.split.link4pay && (
                  <List
                    size="small"
                    header={<div>Link4pay</div>}
                    dataSource={
                      monthReportsResult[month].summary.split.link4pay as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                )}
              </Col>
              <Col span={4}>
                {monthReportsResult[month].summary.split.vibrant && (
                  <List
                    size="small"
                    header={<div>Vibrant</div>}
                    dataSource={
                      monthReportsResult[month].summary.split.vibrant as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                )}
              </Col>
              <Col span={5}>
                {monthReportsResult[month].summary.split.eur_fees_collected && (
                  <List
                    size="small"
                    header={<div>EUR Fees payouts</div>}
                    dataSource={
                      monthReportsResult[month].summary.split
                        .eur_fees_collected as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row gutter={22}>
              <Col span={1}>Total</Col>
              <Col span={3}>
                <TotalContent>
                  {formatCurrency(
                    (monthReportsResult[month].summary.revenue as [
                      {
                        amount: number;
                        currency: string;
                        description: string;
                      },
                    ]).reduce((acc, { amount }) => acc + amount, 0),
                    'GBP',
                  )}
                </TotalContent>
              </Col>
              <Col span={4}>
                <TotalContent>
                  {formatCurrency(
                    (monthReportsResult[month].summary.split.clearhaus as [
                      {
                        amount: number;
                        currency: string;
                        description: string;
                      },
                    ])
                      .filter(({ currency }) => currency === 'GBP')
                      .reduce((acc, { amount }) => acc + amount, 0),
                    'GBP',
                  )}
                </TotalContent>
              </Col>
              <Col span={5}>
                <TotalContent>
                  {monthReportsResult[month].summary.split.link4pay &&
                    formatCurrency(
                      (monthReportsResult[month].summary.split.link4pay as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      'GBP',
                    )}
                </TotalContent>
              </Col>
              <Col span={4}>
                <TotalContent>
                  {monthReportsResult[month].summary.split.vibrant &&
                    formatCurrency(
                      (monthReportsResult[month].summary.split.vibrant as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      'GBP',
                    )}
                </TotalContent>
              </Col>
            </Row>
            <Divider />
            {currencies.map((c) => (
              <Content key={'tis'}>
                <Row key={c} gutter={16}>
                  <Col span={4}>
                    <Statistic
                      title="Txn Count"
                      value={
                        monthReportsResult[month].summary.transactions[c]
                          ? monthReportsResult[month].summary.transactions[c]
                              .no_transactions
                          : 0
                      }
                    />
                  </Col>
                  <Col span={4}>
                    <Statistic
                      title="Txn Amount"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[c]
                          ? monthReportsResult[month].summary.transactions[c]
                              .txn_amount
                          : 0,
                        c,
                      )}
                    />
                  </Col>
                  <Col span={4}>
                    <Statistic
                      title="MDR Amount"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[c]
                          ? monthReportsResult[month].summary.transactions[c]
                              .pct_txn_fee_amount
                          : 0,
                        c,
                      )}
                    />
                  </Col>
                  <Col span={4}>
                    <Statistic
                      title="Fixed Txn Fees"
                      value={formatCurrency(
                        monthReportsResult[month].summary.transactions[c]
                          ? monthReportsResult[month].summary.transactions[c]
                              .txn_fixed_fee_settlement_amount
                          : 0,
                        c,
                      )}
                    />
                  </Col>
                  <Col span={4}>
                    <Statistic
                      title="Settlements Amount"
                      value={
                        monthReportsResult[month].summary.settlements[c]
                          ? formatCurrency(
                              monthReportsResult[month].summary.settlements[c]
                                .settlement_amount,
                              c,
                            )
                          : 0
                      }
                    />
                  </Col>
                  <Col span={3}>
                    <Statistic
                      title="No. settlements"
                      value={
                        monthReportsResult[month].summary.settlements[c]
                          ? monthReportsResult[month].summary.settlements[c]
                              .no_settlements
                          : 0
                      }
                    />
                  </Col>
                </Row>
              </Content>
            ))}
            {contracts
              .filter(
                (c) =>
                  monthReportsResult[month].summary.transactions_breakdown[c],
              )
              .map((c) => (
                <Content key={c}>
                  <h2>{c}</h2>
                  <Row key={c} gutter={16}>
                    {['domestic', 'inter'].map((region) =>
                      ['consumer', 'commercial'].map((product) => (
                        <Col key={`${region}-${product}`} span={4}>
                          <Statistic
                            title={`${region} - ${product}`}
                            value={
                              monthReportsResult[month].summary
                                .transactions_breakdown[c]['GBP'][region][
                                product
                              ]['no_transactions']
                            }
                          />
                        </Col>
                      )),
                    )}
                  </Row>
                </Content>
              ))}
          </Panel>
        ))}
      </Collapse>
      ,
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { MonthlyReportsPage, MonthlyGamblingReportsPage };
