/**
 * Imports
 */
import { List, Statistic, Row, Col, Divider, Typography } from 'antd';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { VibrantlyState } from 'store/types';
import { styled } from 'theme';
import { Collapse } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;
const { Panel } = Collapse;
/**
 * Styling
 */
const Content = styled.div`
  padding: 24px;
`;

const TotalContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * Types
 */
// export interface MonthlyReportsPageProps {}

type MerchantSettlementBalance = {
  fxd_txn_fee_amount: number;
  last_update: string;
  pct_txn_fee_amount: number;
  ready_for_payout: boolean;
  settlement_balance_amount: number;
  settlement_id: string;
  setup_fee_amount: number;
  terminal_fee_amount: number;
  transfer_fee_amount: number;
  txn_amount: number;
  txn_count: number;
};
/**
 * HomePage component
 */

const MonthlyUCCReportsPage: React.FC<{ currency: string }> = ({
  currency,
}) => {
  useFirestoreConnect([`monthlyReports_UCC_${currency}`]);

  const [showSplit, setShowSplit] = React.useState(true);

  const getFormat = (currency: string): string => {
    if (currency === 'GBP') {
      return 'gb-UK';
    } else if (currency === 'DKK') {
      return 'da-DK';
    } else {
      return 'en-GB';
    }
  };
  const monthReportsResult = useSelector(
    (state: VibrantlyState) =>
      state.firestore.data[`monthlyReports_UCC_${currency}`],
  );

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency,
    }).format(amount / 100);
  };

  const collectedFeeColSize = 4; //showSplit ? 4 : 5;
  const clearhausColSize = 5; //showSplit ? 4 : 6;
  const vibrantL4PColSize = 5;
  // const link4payColSize = 5;

  if (!monthReportsResult) {
    return (
      <BaseLayout>
        <Content>Monthly reports</Content>
      </BaseLayout>
    );
  }

  const currencies = [currency];

  const contracts =
    currency === 'EUR'
      ? ['ucc_coop', 'ucc_ie', 'ucc_nl']
      : currency === 'GBP'
      ? ['ucc_uk']
      : [];

  const fee_currency = currency === 'GBP' ? 'GBP' : 'EUR';

  const months = Object.keys(monthReportsResult).sort((v1, v2) =>
    v2.localeCompare(v1),
  );
  return (
    <BaseLayout>
      <Content>
        UCC Monthly Reports
        <input
          type="checkbox"
          checked={showSplit}
          onChange={(e) => setShowSplit(e.target.checked)}
        />
      </Content>
      <Collapse
        defaultActiveKey={[months[0]]}
        onChange={(k) => {
          console.log(k);
        }}
      >
        {months
          .filter((month) => monthReportsResult[month].no_merchants > 0)
          .map((month) => (
            <Panel header={month} key={month}>
              <Row gutter={20}>
                <Col span={2}>
                  <Statistic
                    title="No. merchant"
                    value={monthReportsResult[month].no_merchants}
                  />
                </Col>
                <Col span={2}>
                  <Statistic
                    title="No. locations"
                    value={monthReportsResult[month].no_locations}
                  />
                </Col>

                <Col span={2}>
                  <Statistic
                    title="No. terminals"
                    value={monthReportsResult[month].no_terminals}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={collectedFeeColSize}>
                  <List
                    size="small"
                    header={<div>Collected Fees</div>}
                    dataSource={
                      monthReportsResult[month].summary.collected_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                </Col>
                <Col span={4}>
                  <List
                    size="small"
                    header={<div>Charged EUR Fees</div>}
                    dataSource={
                      monthReportsResult[month].summary.charged_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                </Col>
                <Col span={clearhausColSize}>
                  <List
                    size="small"
                    header={<div>Clearhaus</div>}
                    dataSource={
                      monthReportsResult[month].summary.split.clearhaus
                        .breakdown as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]
                    }
                    renderItem={({ currency, amount, description }) => (
                      <Row justify="space-between">
                        <Col>{`${description}`}</Col>
                        <Col>{formatCurrency(amount, currency)}</Col>
                      </Row>
                    )}
                  />
                </Col>
                {showSplit && (
                  <Col span={5}>
                    <List
                      size="small"
                      header={<div>Worldline</div>}
                      dataSource={
                        monthReportsResult[month].summary.split.worldline
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, fee_currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                )}
                {showSplit && (
                  <Col span={vibrantL4PColSize}>
                    <List
                      size="small"
                      header={<div>Vibrant/Link4Pay</div>}
                      dataSource={
                        monthReportsResult[month].summary.split.vibrant_l4p
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                )}
                {!showSplit && (
                  <Col span={4}>
                    <List
                      size="small"
                      header={<div>Commission</div>}
                      dataSource={
                        monthReportsResult[month].summary.commission
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={1}>Total</Col>
                <Col span={collectedFeeColSize - 1}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.collected_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      currency,
                    )}
                  </TotalContent>
                </Col>
                <Col span={4}>
                  <TotalContent>{formatCurrency(0, currency)}</TotalContent>

                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.charged_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      'EUR',
                    )}
                  </TotalContent>
                </Col>
                <Col span={clearhausColSize}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.split.clearhaus
                        .breakdown as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ])
                        .filter(
                          ({ currency: row_currency }) =>
                            row_currency == currency,
                        )
                        .reduce((acc, { amount }) => acc + amount, 0),
                      currency,
                    )}
                  </TotalContent>
                  {currency != 'EUR' && (
                    <TotalContent>
                      {formatCurrency(
                        (monthReportsResult[month].summary.split.clearhaus
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ])
                          .filter(({ currency }) => currency == 'EUR')
                          .reduce((acc, { amount }) => acc + amount, 0),
                        'EUR',
                      )}
                    </TotalContent>
                  )}
                </Col>
                {showSplit && (
                  <Col span={5}>
                    <TotalContent>
                      {formatCurrency(
                        (monthReportsResult[month].summary.split.worldline
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ])
                          .filter(
                            ({ currency: c_currency }) =>
                              c_currency === currency,
                          )
                          .reduce((acc, { amount }) => acc + amount, 0),
                        currency,
                      )}
                    </TotalContent>
                    {currency != 'EUR' && (
                      <TotalContent>
                        {formatCurrency(
                          (monthReportsResult[month].summary.split.worldline
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(({ currency }) => currency == 'EUR')
                            .reduce((acc, { amount }) => acc + amount, 0),
                          'EUR',
                        )}
                      </TotalContent>
                    )}
                  </Col>
                )}
                {showSplit && (
                  <Col span={vibrantL4PColSize}>
                    <TotalContent>
                      {formatCurrency(
                        (monthReportsResult[month].summary.split.vibrant_l4p
                          .breakdown as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]).reduce((acc, { amount }) => acc + amount, 0),
                        currency,
                      )}
                    </TotalContent>
                    {currency != 'EUR' && (
                      <TotalContent>
                        {formatCurrency(
                          (monthReportsResult[month].summary.split.vibrant_l4p
                            .breakdown as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ])
                            .filter(({ currency }) => currency == 'EUR')
                            .reduce((acc, { amount }) => acc + amount, 0),
                          'EUR',
                        )}
                      </TotalContent>
                    )}
                  </Col>
                )}

                {!showSplit && (
                  <Col span={4}>
                    <TotalContent>
                      {formatCurrency(
                        monthReportsResult[month].summary.commission.total
                          .amount,
                        monthReportsResult[month].summary.commission.total
                          .currency,
                      )}
                    </TotalContent>
                  </Col>
                )}
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={1} />
                <Col span={collectedFeeColSize - 1}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.collected_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      fee_currency,
                    )}
                  </TotalContent>
                </Col>
                <Col span={4} />
                <Col span={clearhausColSize}>
                  <TotalContent>
                    {formatCurrency(
                      monthReportsResult[month].summary.split.clearhaus.total,
                      currency,
                    )}
                  </TotalContent>
                </Col>
                {showSplit && (
                  <Col span={5}>
                    <TotalContent>
                      {formatCurrency(
                        monthReportsResult[month].summary.split.worldline.total,
                        currency,
                      )}
                    </TotalContent>
                  </Col>
                )}
                {showSplit && (
                  <Col span={vibrantL4PColSize}>
                    <TotalContent>
                      {formatCurrency(
                        monthReportsResult[month].summary.split.vibrant_l4p
                          .total,
                        currency,
                      )}
                    </TotalContent>
                  </Col>
                )}

                {!showSplit && (
                  <Col span={4}>
                    <TotalContent>
                      {formatCurrency(
                        monthReportsResult[month].summary.commission.total
                          .amount,
                        monthReportsResult[month].summary.commission.total
                          .currency,
                      )}
                    </TotalContent>
                  </Col>
                )}
              </Row>

              <Divider />
              {currencies.map((c) => (
                <Content key={'tis'}>
                  <Row key={c} gutter={16}>
                    <Col span={2}>
                      <Statistic
                        title="No. settlements"
                        value={
                          monthReportsResult[month].summary.settlements[c]
                            ? monthReportsResult[month].summary.settlements[c]
                                .no_settlements
                            : 0
                        }
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="Txn Count"
                        value={
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .no_transactions
                            : 0
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Statistic
                        title="Txn Amount"
                        value={formatCurrency(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .txn_amount
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="MDR Amount"
                        value={formatCurrency(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .mdr_amount
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="IRF Amount"
                        value={formatCurrency(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .irf_amount
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="Auth Fees"
                        value={formatCurrency(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .auth_fee.settlement_amount
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="Scheme Fees"
                        value={formatCurrency(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .scheme_fee.settlement_amount
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={4}>
                      <Statistic
                        title="Settlements Amount"
                        value={
                          monthReportsResult[month].summary.settlements[c]
                            ? formatCurrency(
                                monthReportsResult[month].summary.settlements[c]
                                  .settlement_amount,
                                c,
                              )
                            : 0
                        }
                      />
                    </Col>
                  </Row>
                </Content>
              ))}
              {contracts
                .filter(
                  (c) =>
                    monthReportsResult[month].summary.transactions_breakdown[c],
                )
                .map((c) => (
                  <Content key={c}>
                    <h2>{c}</h2>
                    <Row key={c} gutter={16}>
                      {['domestic', 'international'].map((region) =>
                        ['consumer', 'commercial'].map((product) => (
                          <Col key={`${region}-${product}`} span={4}>
                            <Statistic
                              title={`${region} - ${product}`}
                              value={
                                monthReportsResult[month].summary
                                  .transactions_breakdown[c][currency][region][
                                  product
                                ]['no_transactions']
                              }
                            />
                          </Col>
                        )),
                      )}
                    </Row>
                  </Content>
                ))}
            </Panel>
          ))}
      </Collapse>
    </BaseLayout>
  );
};

const UCCMonthlyReportsPage: React.FC<{ currency: string }> = ({
  currency,
}) => {
  useFirestoreConnect(['UCCmonthlyReports']);

  const getFormat = (currency: string): string => {
    if (currency === 'GBP') {
      return 'gb-UK';
    } else if (currency === 'DKK') {
      return 'da-DK';
    } else {
      return 'en-GB';
    }
  };
  const monthReportsResult = useSelector(
    (state: VibrantlyState) => state.firestore.data.UCCmonthlyReports,
  );

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency,
    }).format(amount / 100);
  };

  const formatCurrencyE6 = (amount: number, currency: string) => {
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency,
    }).format(amount / 1000000);
  };

  if (!monthReportsResult) {
    return (
      <BaseLayout>
        <Content>Monthly reports</Content>
      </BaseLayout>
    );
  }

  const currencies = [currency];

  const contracts = ['ucc_coop'];

  const months = Object.keys(monthReportsResult).sort((v1, v2) =>
    v2.localeCompare(v1),
  );
  return (
    <BaseLayout>
      <Content>Monthly Reports</Content>
      <Collapse
        defaultActiveKey={[months[0]]}
        onChange={(k) => {
          console.log(k);
        }}
      >
        {months
          .filter((month) => monthReportsResult[month].no_merchants > 0)
          .map((month) => (
            <Panel header={month} key={month}>
              <Row gutter={20}>
                <Col span={2}>
                  <Statistic
                    title="No. merchant"
                    value={monthReportsResult[month].no_merchants}
                  />
                </Col>
                <Col span={2}>
                  <Statistic
                    title="No. terminals"
                    value={monthReportsResult[month].no_terminals}
                  />
                </Col>
              </Row>
              {monthReportsResult[month].summary && (
                <Row gutter={20}>
                  <Col span={4}>
                    {monthReportsResult[month].summary && (
                      <List
                        size="small"
                        header={<div>Collected Fees</div>}
                        dataSource={
                          monthReportsResult[month].summary.collected_fees as [
                            {
                              amount: number;
                              currency: string;
                              description: string;
                            },
                          ]
                        }
                        renderItem={({ currency, amount, description }) => (
                          <Row justify="space-between">
                            <Col>{`${description}`}</Col>
                            <Col>{formatCurrency(amount, currency)}</Col>
                          </Row>
                        )}
                      />
                    )}
                  </Col>
                  <Col span={4}>
                    <List
                      size="small"
                      header={<div>Charged EUR Fees</div>}
                      dataSource={
                        monthReportsResult[month].summary.charged_fees as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                  <Col span={5}>
                    <List
                      size="small"
                      header={<div>Clearhaus</div>}
                      dataSource={
                        monthReportsResult[month].summary.split.clearhaus as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                  <Col span={5}>
                    <List
                      size="small"
                      header={<div>Worldline</div>}
                      dataSource={
                        monthReportsResult[month].summary.split.worldline as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                  <Col span={5}>
                    <List
                      size="small"
                      header={<div>Vibrant/Link4Pay</div>}
                      dataSource={
                        monthReportsResult[month].summary.split.vibrant_l4p as [
                          {
                            amount: number;
                            currency: string;
                            description: string;
                          },
                        ]
                      }
                      renderItem={({ currency, amount, description }) => (
                        <Row justify="space-between">
                          <Col>{`${description}`}</Col>
                          <Col>{formatCurrency(amount, currency)}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={1}>Total</Col>
                <Col span={3}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.collected_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      currency,
                    )}
                  </TotalContent>
                </Col>
                <Col span={4}>
                  <TotalContent>{formatCurrency(0, currency)}</TotalContent>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.charged_fees as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      'EUR',
                    )}
                  </TotalContent>
                </Col>
                <Col span={5}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.split.clearhaus as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ])
                        .filter(
                          ({ currency: amount_currency }) =>
                            amount_currency == currency,
                        )
                        .reduce((acc, { amount }) => acc + amount, 0),
                      currency,
                    )}
                  </TotalContent>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.split.clearhaus as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ])
                        .filter(({ currency }) => currency == 'EUR')
                        .reduce((acc, { amount }) => acc + amount, 0),
                      'EUR',
                    )}
                  </TotalContent>
                </Col>
                <Col span={5}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.split.worldline as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ])
                        .filter(({ currency }) => currency == currency)
                        .reduce((acc, { amount }) => acc + amount, 0),
                      currency,
                    )}
                  </TotalContent>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.split.worldline as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ])
                        .filter(({ currency }) => currency == 'EUR')
                        .reduce((acc, { amount }) => acc + amount, 0),
                      'EUR',
                    )}
                  </TotalContent>
                </Col>
                <Col span={5}>
                  <TotalContent>
                    {formatCurrency(
                      (monthReportsResult[month].summary.split.vibrant_l4p as [
                        {
                          amount: number;
                          currency: string;
                          description: string;
                        },
                      ]).reduce((acc, { amount }) => acc + amount, 0),
                      currency,
                    )}
                  </TotalContent>
                </Col>
              </Row>
              <Divider />
              {currencies.map((c) => (
                <Content key={'tis'}>
                  <Row key={c} gutter={16}>
                    <Col span={2}>
                      <Statistic
                        title="No. settlements"
                        value={
                          monthReportsResult[month].summary.settlements[c]
                            ? monthReportsResult[month].summary.settlements[c]
                                .no_settlements
                            : 0
                        }
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="Txn Count"
                        value={
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .no_transactions
                            : 0
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Statistic
                        title="Txn Amount"
                        value={formatCurrency(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .txn_amount
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="MDR Amount"
                        value={formatCurrencyE6(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .mdr_amount_e6
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="IRF Amount"
                        value={formatCurrencyE6(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .irf_amount_e6
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="Auth Fees"
                        value={formatCurrencyE6(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .auth_fee.settlement_amount_e6
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={2}>
                      <Statistic
                        title="Scheme Fees"
                        value={formatCurrencyE6(
                          monthReportsResult[month].summary.transactions[c]
                            ? monthReportsResult[month].summary.transactions[c]
                                .scheme_fee.settlement_amount_e6
                            : 0,
                          c,
                        )}
                      />
                    </Col>
                    <Col span={4}>
                      <Statistic
                        title="Settlements Amount"
                        value={
                          monthReportsResult[month].summary.settlements[c]
                            ? formatCurrency(
                                monthReportsResult[month].summary.settlements[c]
                                  .settlement_amount,
                                c,
                              )
                            : 0
                        }
                      />
                    </Col>
                  </Row>
                </Content>
              ))}
              {contracts
                .filter(
                  (c) =>
                    monthReportsResult[month].summary.transactions_breakdown[c],
                )
                .map((c) => (
                  <Content key={c}>
                    <h2>{c}</h2>
                    <Row key={c} gutter={16}>
                      {['domestic', 'international'].map((region) =>
                        ['consumer', 'commercial'].map((product) => (
                          <Col key={`${region}-${product}`} span={4}>
                            <Statistic
                              title={`${region} - ${product}`}
                              value={
                                monthReportsResult[month].summary
                                  .transactions_breakdown[c][currency][region][
                                  product
                                ]['no_transactions']
                              }
                            />
                          </Col>
                        )),
                      )}
                    </Row>
                  </Content>
                ))}
            </Panel>
          ))}
      </Collapse>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { UCCMonthlyReportsPage, MonthlyUCCReportsPage };
