import { getFirebaseInstance } from './firebaseAdapter';
import { User } from 'features/user/types';
import { PROJECT_API_URL } from 'config';
import axios from 'axios';

const firebaseLogin = async (
  email: string,
  password: string,
): Promise<User> => {
  const firebase = getFirebaseInstance();
  const signinResult = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  if (!signinResult.user) {
    throw new Error('Could not sign in user.');
  }

  return getUser(signinResult.user.uid);
};

const getIdToken = async (): Promise<string> => {
  const firebase = getFirebaseInstance();
  const token = await firebase.auth().currentUser!.getIdToken();
  return token;
};

const firebaseLogout = async (): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase.auth().signOut();
};

const fetchUser = async (uid: string) => {
  const userEntry = await getFirebaseInstance()
    .firestore()
    .doc(`/users/${uid}`)
    .get();

  if (userEntry) {
    return userEntry.data() as User;
  } else {
    return userEntry;
  }
};

const getUser = async (userId: string): Promise<User> => {
  // const token = await getIdToken();

  // const firebase = getFirebaseInstance();

  // const uid = await firebase.auth().currentUser?.uid;
  // const userEntry = await firebase.firestore().doc(`users/${userId}`).get();

  console.log(userId);
  const userEntry = await fetchUser(userId);

  // const response = await axios.get(`${PROJECT_API_URL}/api/me`, {
  //   headers: { token },
  // });
  // const user = response.data.result as User;

  if (userEntry.userRoles.includes('admin')) {
    return userEntry;
  } else {
    throw new Error('Only admins are allowed here');
  }
};

export { firebaseLogout, firebaseLogin, getIdToken, getUser };
