/**
 * Project API url
 */
const getprojectApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('dev')) {
    return 'https://api.dev.vibrant.one';
  }
  if (hostname.includes('uat')) {
    return 'https://api.uat.vibrant.one';
  }
  if (hostname.includes('bcd.vibrant.one')) {
    return 'https://api.vibrant.one';
  }
  /// should be used for localhost
  // return 'https://api.uat.vibrant.one';
  return 'https://api.dev.vibrant.one';
  // return 'http://localhost:3002';
};
export const PROJECT_API_URL = getprojectApiUrl();
/**
 * Firebase environment settings
 */
const getFirebaseConfig = () => {
  const hostname = window.location.hostname;

  if (hostname.includes('uat')) {
    console.log('Running uat');
    return {
      apiKey: 'AIzaSyDVDd4j87hxMN6VRnxKqV9Pv2ynjCDp_CI',
      appId: '1:922162229168:web:6807b7c80433568a5a7075',
      authDomain: 'vibrant-fb-uat.firebaseapp.com',
      databaseURL: 'https://vibrant-fb-uat.firebaseio.com',
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      measurementId: 'G-M8FF6ETJVM',
      messagingSenderId: '922162229168',
      projectId: 'vibrant-fb-uat',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
      storageBucket: 'vibrant-fb-uat.appspot.com',
    };
  }
  if (hostname.includes('bcd.vibrant.one')) {
    console.log('Running production');
    return {
      apiKey: 'AIzaSyC7k7oedczd43hXiHbBfxWh3kmpViWSpNU',
      authDomain: 'vibrant-billing-prod.firebaseapp.com',
      projectId: 'vibrant-billing-prod',
      storageBucket: 'vibrant-billing-prod.appspot.com',
      messagingSenderId: '500210071223',
      appId: '1:500210071223:web:0917f6f2ffeeb3a09fbf32',
      // apiKey: 'AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o',
      // appId: '1:836686013166:web:7dd1e6a0bdf1754ec409ff',
      // authDomain: 'vibrantly-production.firebaseapp.com',
      // databaseURL: 'https://vibrantly-production.firebaseio.com',
      // environment: 'production',
      // gitRelease: 'PENDING_1.0.0',
      // measurementId: 'G-392EBWQMDQ',
      // messagingSenderId: '836686013166',
      // projectId: 'vibrantly-production',
      // sentryDsn:
      //   'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
      // storageBucket: 'vibrantly-production.appspot.com',
    };
  }
  console.log('Running dev');
  return {
    apiKey: 'AIzaSyAhNTU2RHO-SnwCG6i20bA8v55cMqMf1LY',
    authDomain: 'vibrant-billing-local-a6371.firebaseapp.com',
    projectId: 'vibrant-billing-local-a6371',
    storageBucket: 'vibrant-billing-local-a6371.appspot.com',
    messagingSenderId: '1061170704719',
    appId: '1:1061170704719:web:1768ef2e20dd4b02ec553a',
    // apiKey: 'AIzaSyCv-etousHNny0jlIiLLkXopYTyBJbeBLY',
    // appId: '1:400993474174:web:b297881fc814d5d3d42a2f',
    // authDomain: 'staging-727fe.firebaseapp.com',
    // databaseURL: 'https://staging-727fe.firebaseio.com',
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    // measurementId: 'G-48TVV2XPTR',
    // messagingSenderId: '400993474174',
    // projectId: 'staging-727fe',
    // sentryDsn:
    //   'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    // storageBucket: 'staging-727fe.appspot.com',
  };
};
export const FIREBASE_CONFIG = getFirebaseConfig();
/**
 * Project environment settings
 */
const getProjectConfig = () => {
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    };
  }
  console.log('Running dev');
  return {
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    sentryDsn:
      'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
  };
};
export const PROJECT_CONFIG = getProjectConfig();
