/**
 * Imports
 */
import { Table, Statistic, Row, Col, Divider } from 'antd';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { VibrantlyState } from 'store/types';
import { styled } from 'theme';
import moment from 'moment';
import { Typography } from 'antd';
import { maybeUserSelector } from 'features/user/redux/selectors';
const { Title } = Typography;

/**
 * Styling
 */
const Content = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface HomePageProps {}

type MerchantSettlementBalance = {
  fxd_txn_fee_amount: number;
  last_update: string;
  pct_txn_fee_amount: number;
  ready_for_payout: boolean;
  settlement_balance_amount: number;
  previous_settlement_amount: number;
  settlement_currency_code: string;
  fee_currency_code: string;
  settlement_id: string;
  setup_fee_amount: number;
  terminal_fee_amount: number;
  transfer_fee_amount: number;
  txn_amount: number;
  txn_count: number;
  opened_at: string;
};
/**
 * HomePage component
 */
const HomePage: React.FC<HomePageProps> = (_props) => {
  useFirestoreConnect([
    {
      collection: 'merchant_settlements',
      doc: 'balance',
      storeAs: 'balanceOverview',
    },
    {
      collection: 'merchant_settlements',
      doc: 'balance',
      subcollections: [{ collection: 'balances' }],
      storeAs: 'merchantBalances',
    },
  ]);
  const user = useSelector(maybeUserSelector);

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency,
    }).format(amount / 100);
  };

  const merchantBalanceResult = useSelector(
    (state: VibrantlyState) => state.firestore.data.balanceOverview,
  );

  const merchantBalancesResult = useSelector(
    (state: VibrantlyState) => state.firestore.data.merchantBalances,
  );

  if (!user || !merchantBalanceResult) {
    return (
      <BaseLayout>
        <Content>Merchant Settlements</Content>
      </BaseLayout>
    );
  }
  const ready_summary: {
    txn_count: number;
    txn_amount: { EUR: number; GBP: number };
    setup_fee_amount: number;
    terminal_fee_amount: number;
    transfer_fee_amount: number;
  } = Object.values<MerchantSettlementBalance>(merchantBalancesResult || {})
    .filter(({ ready_for_payout }) => ready_for_payout)
    .reduce(
      (
        acc,
        {
          txn_count,
          txn_amount,
          transfer_fee_amount,
          settlement_currency_code,
        },
      ) => {
        return {
          ...acc,
          setup_fee_amount: 0,
          terminal_fee_amount: 0,
          transfer_fee_amount: acc.transfer_fee_amount + transfer_fee_amount,
          txn_count: acc.txn_count + txn_count,
          txn_amount: {
            ...acc.txn_amount,
            [settlement_currency_code]:
              acc.txn_amount[settlement_currency_code as 'EUR' | 'GBP'] +
              txn_amount,
          },
        };
      },
      {
        setup_fee_amount: 0,
        terminal_fee_amount: 0,
        transfer_fee_amount: 0,
        txn_count: 0,
        txn_amount: { EUR: 0, GBP: 0 },
      },
    );

  const data = Object.entries(
    (merchantBalancesResult as Map<string, MerchantSettlementBalance>) || {},
  ).map(([k, v]) => ({
    ...v,
    merchant_id: k,
    key: k,
  }));

  const columns = [
    {
      title: 'Merchant Id',
      dataIndex: 'merchant_id',
      key: 'merchant_id',
    },
    {
      title: 'Settlement balance',
      key: 'settlement_balance_amount',

      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(
          record.settlement_balance_amount,
          record.settlement_currency_code,
        ),
      sorter: {
        defaultSortOrder: 'descend',
        compare: (a: MerchantSettlementBalance, b: MerchantSettlementBalance) =>
          a.settlement_balance_amount - b.settlement_balance_amount,
        multiple: 1,
      },
    },
    {
      title: 'Prev. Settlement Amount',
      key: 'previous_settlement_amount',

      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(
          record.previous_settlement_amount,
          record.settlement_currency_code,
        ),
    },
    {
      title: 'Txn Amount',
      dataIndex: 'txn_amount',
      key: 'txn_amount',
      sorter: {
        compare: (a: MerchantSettlementBalance, b: MerchantSettlementBalance) =>
          a.txn_amount - b.txn_amount,
        multiple: 1,
      },
      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(record.txn_amount, record.settlement_currency_code),
    },
    {
      title: 'Pct. fee',
      dataIndex: 'pct_txn_fee_amount',
      key: 'pct_txn_fee_amount',
      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(
          record.pct_txn_fee_amount,
          record.settlement_currency_code,
        ),
    },
    {
      title: 'Fixed fee',
      dataIndex: 'fxd_txn_fee_amount',
      key: 'fxd_txn_fee_amount',
      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(record.fxd_txn_fee_amount, record.fee_currency_code),
    },
    {
      title: '#Txns',
      dataIndex: 'txn_count',
      key: 'txn_count',
    },
    {
      title: 'Setup Fee',
      dataIndex: 'setup_fee_amount',
      key: 'setup_fee_amount',
      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(record.setup_fee_amount, record.fee_currency_code),
    },
    {
      title: 'Terminal Fee',
      dataIndex: 'terminal_fee_amount',
      key: 'terminal_fee_amount',
      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(record.terminal_fee_amount, record.fee_currency_code),
    },
    {
      title: 'Transfer Fee',
      dataIndex: 'transfer_fee_amount',
      key: 'transfer_fee_amount',
      render: (_text: any, record: MerchantSettlementBalance) =>
        formatCurrency(record.transfer_fee_amount, record.fee_currency_code),
    },
    {
      title: 'Opened',
      dataIndex: 'opened_at',
      key: 'opened_at',
      render: (_text: any, record: MerchantSettlementBalance) =>
        moment(record.opened_at).fromNow(),
    },
    {
      title: 'Batch',
      dataIndex: 'settlement_id',
      key: 'settlement_id',
    },
    {
      title: 'Ready for Payout',
      dataIndex: 'ready_for_payout',
      key: 'ready_for_payout',
      render: (b: boolean) => {
        return b ? 'yes' : 'no';
      },
      onFilter: (value: any, record: MerchantSettlementBalance) =>
        value === 'yes' ? record.ready_for_payout : !record.ready_for_payout,
      filters: [
        { text: 'no', value: 'no' },
        { text: 'yes', value: 'yes' },
      ],
    },
  ];

  return (
    <BaseLayout>
      <Content>
        <Row gutter={16}>
          <Col>
            <Title level={5}>Total</Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={3}>
            <Statistic
              title="Txns Count"
              value={merchantBalanceResult.txn_count}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Txn Amount GBP"
              value={formatCurrency(
                merchantBalanceResult.txn_amount
                  ? merchantBalanceResult.txn_amount.GBP
                  : 0,
                'GBP',
              )}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Txn Amount EUR"
              value={formatCurrency(
                merchantBalanceResult.txn_amount
                  ? merchantBalanceResult.txn_amount.EUR
                  : 0,
                'EUR',
              )}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Setup Fee"
              value={formatCurrency(
                merchantBalanceResult.setup_fee_amount,
                'EUR',
              )}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Terminal Fee"
              value={formatCurrency(
                merchantBalanceResult.terminal_fee_amount,
                'EUR',
              )}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Transfer Fee"
              value={formatCurrency(
                merchantBalanceResult.transfer_fee_amount,
                'EUR',
              )}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="#Merchants"
              value={
                Object.values<MerchantSettlementBalance>(
                  merchantBalancesResult || {},
                ).length
              }
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Divider />
        </Row>
        <Row gutter={16}>
          <Col>
            <Title level={5}>Ready for transfer</Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={3}>
            <Statistic title="Txns Count" value={ready_summary.txn_count} />
          </Col>
          <Col span={3}>
            <Statistic
              title="Txn Amount GBP"
              value={formatCurrency(ready_summary.txn_amount.GBP, 'GBP')}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Txn Amount EUR"
              value={formatCurrency(ready_summary.txn_amount.EUR, 'EUR')}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Setup Fee"
              value={formatCurrency(ready_summary.setup_fee_amount, 'EUR')}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Terminal Fee"
              value={formatCurrency(ready_summary.terminal_fee_amount, 'EUR')}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Transfer Fee"
              value={formatCurrency(ready_summary.transfer_fee_amount, 'EUR')}
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="#Merchants"
              value={
                Object.values<MerchantSettlementBalance>(
                  merchantBalancesResult || {},
                ).filter(({ ready_for_payout }) => ready_for_payout).length
              }
            />
          </Col>
          <Col span={3}>
            <Statistic
              title="Next Settlement Batch"
              value={merchantBalanceResult.settlement_batch_no}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Divider />
        </Row>
        <Row>
          <Col span={24}>
            <Table
              size="small"
              pagination={{ showSizeChanger: true }}
              bordered
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
      </Content>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { HomePage };
